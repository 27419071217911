import { useModule } from '@/controllers/manager'

export default {
  namespaced: true,
  state: {
    module: 'demands',
    loading: false,
  },
  getters: {},
  mutations: {
    SET_LOADING(state, payload) {
      state.loading = payload
    },
  },
  actions: {
    async fetchApplicants({ state, commit }) {
      commit('SET_LOADING', true)

      const controller = useModule(state.module)

      try {
        return controller.getAllApplicants()
      } catch (e) {
        throw e
      } finally {
        commit('SET_LOADING', false)
      }
    },
    async fetchDemands({ state, commit }, payload) {
      commit('SET_LOADING', true)

      const controller = useModule(state.module)

      try {
        return await controller.getAll(payload)
      } catch (e) {
        throw e
      } finally {
        commit('SET_LOADING', false)
      }
    },
    async fetchOneDemand({ state, commit }, payload) {
      commit('SET_LOADING', true)

      const controller = useModule(state.module)

      try {
        return await controller.getById(payload)
      } catch {
        throw e
      } finally {
        commit('SET_LOADING', false)
      }
    },
    async setDemand({ commit, state }, payload) {
      commit('SET_LOADING', true)

      const controller = useModule(state.module)

      try {
        if (payload.id) {
          await controller.update(payload)
        } else {
          await controller.create(payload)
        }
      } catch (e) {
        throw e
      } finally {
        commit('SET_LOADING', false)
      }
    },
    async deleteDemand({ commit, state }, payload) {
      commit('SET_LOADING', true)

      const controller = useModule(state.module)

      try {
        await controller.deleteById(payload)
      } catch (e) {
        throw e
      } finally {
        commit('SET_LOADING', false)
      }
    },
  },
}
