<template>
  <div>
    <!-- Table Container Card -->
    <b-card no-body class="mb-0">
      <div class="m-2">
        <!-- Table Top -->
        <b-row>
          <!-- Per Page -->
          <b-col
            cols="12"
            md="6"
            class="d-flex align-items-center justify-content-start mb-1 mb-md-0"
          >
            <label>{{ $t('commons.show') }}</label>
            <v-select
              v-model="perPage"
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="perPageOptions"
              :clearable="false"
              class="per-page-selector d-inline-block mx-50"
            />
            <label>{{ $t('commons.entries') }}</label>
          </b-col>

          <!-- Search -->
          <b-col cols="12" md="6">
            <div class="d-flex align-items-center justify-content-end">
              <b-form-input
                v-model="refQuery"
                class="d-inline-block mr-1"
                :placeholder="$t('demandsTable.ref')"
                style="width: 60px"
              />

              <v-select
                v-model="selectedPropertyTypes"
                multiple
                :placeholder="$t('demandsPlaceholders.propertyTypes')"
                style="width: 260px"
                class="mr-1"
                :options="
                  (propertyTypes || []).map((e) => ({
                    ...e,
                    label: e.label[$i18n.locale],
                  }))
                "
              >
                <span slot="no-options" @click="$refs.select.open = false">
                  {{ $t('alert.select.empty') }}
                </span>
              </v-select>

              <!--<v-select
                v-model="selectedPropertyActions"
                multiple
                :placeholder="$t('demandsPlaceholders.propertyActions')"
                style="width: 260px"
                class="mr-1"
                :options="propertyActions || []"
              />-->

              <b-button
                variant="primary"
                @click="
                  $router.push({ name: 'demand-edit', params: { id: 'new' } })
                "
              >
                <span class="text-nowrap">{{ $t('buttons.create') }}</span>
              </b-button>
            </div>
          </b-col>
        </b-row>
      </div>

      <b-table
        ref="refDemandListTable"
        class="position-relative"
        responsive
        show-empty
        primary-key="id"
        :empty-text="$t('commons.empty')"
        :items="fetchDemand"
        :fields="tableColumns"
        :sort-by.sync="sortBy"
        :sort-desc.sync="isSortDirDesc"
        :busy="!fetchDemand"
      >
        <!-- Table Loading -->
        <template #table-busy>
          <div class="text-center text-primary my-2">
            <b-spinner class="align-middle" small />
            <strong class="ml-1">{{ $t('commons.loading') }}</strong>
          </div>
        </template>

        <template #cell(propertyTypes)="data">
          <div
            v-for="propertyType in data.item.propertyTypes"
            :key="propertyType.id"
          >
            {{ localeTitle(propertyType) }}
          </div>
        </template>

        <template #cell(propertyAction)="data">
          <div>
            {{ localeTitle(data.item.propertyAction) }}
          </div>
        </template>

        <template #cell(priceRange)="data">
          <span>
            {{ formatCurrency(data.item.minPrice) }} -
            {{ formatCurrency(data.item.maxPrice) }}
          </span>
        </template>

        <template #cell(createdAt)="data">
          <span>{{ formatDate(data.item.createdAt) }}</span>
        </template>

        <template #cell(matches)="data">
          <b-badge
            :variant="`light-${
              data.item.matches.length ? 'success' : 'warning'
            }`"
            class="text-capitalize"
          >
            {{ data.item.matches.length }}
          </b-badge>
        </template>

        <!-- Column: isActive -->
        <template #cell(isActive)="data">
          <b-badge
            :variant="`light-${resolveStatusVariant(data.item.isActive)}`"
            class="text-capitalize"
          >
            {{ resolveStatusText(data.item.isActive) }}
          </b-badge>
        </template>

        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-row align-h="around">
            <b-button
              variant="outline-secondary"
              size="sm"
              :id="`view-${data.item.id}`"
              @click="
                matchesModal = true
                selectedItem = data.item
              "
            >
              <feather-icon icon="EyeIcon" />
            </b-button>
            <b-tooltip
              :target="`view-${data.item.id}`"
              triggers="hover"
              placement="left"
            >
              <b>{{ $t('demandsTable.viewMatches') }}</b>
            </b-tooltip>

            <b-button
              variant="outline-secondary"
              size="sm"
              :id="`edit-${data.item.id}`"
              @click="
                $router.push({
                  name: 'demand-edit',
                  params: { id: data.item.id },
                })
              "
            >
              <feather-icon icon="EditIcon" />
            </b-button>
            <b-tooltip
              :target="`edit-${data.item.id}`"
              triggers="hover"
              placement="left"
            >
              <b>{{ $t('alert.edit.title') }}</b>
            </b-tooltip>

            <b-button
              variant="outline-danger"
              size="sm"
              :id="`delete-${data.item.id}`"
              @click="
                deleteModal = true
                selectedItem = data.item
              "
            >
              <feather-icon icon="TrashIcon" />
            </b-button>
            <b-tooltip
              :target="`delete-${data.item.id}`"
              triggers="hover"
              placement="left"
            >
              <b>{{ $t('alert.delete.title') }}</b>
            </b-tooltip>
          </b-row>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted"
              >{{ $t('footer.table.showing') }} {{ dataMeta.from }}
              {{ $t('footer.table.to') }} {{ dataMeta.to }}
              {{ $t('footer.table.of') }} {{ dataMeta.of }}
              {{ $t('footer.table.entries') }}</span
            >
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalDemand"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>

    <b-modal
      centered
      hide-footer
      no-close-on-backdrop
      size="xl"
      body-class="p-0"
      :visible="matchesModal"
      @close="
        matchesModal = false
        selectedMatches = {}
      "
    >
      <PropertyList
        :onlyView="true"
        :demandId="selectedItem.id"
        :items="selectedItem.matches"
      />
    </b-modal>

    <AlertDialog
      :id="`confirmation-${selectedItem.id}`"
      :show="deleteModal"
      :message="$t('alert.delete.message')"
      :title="`${$t('alert.delete.title')} ${selectedItem.name}`"
      @action="
        deleteDemand(selectedItem.id)
        selectedItem = {}
        deleteModal = false
      "
      @close="deleteModal = false"
    />
  </div>
</template>

<script>
import {
  BCard,
  BRow,
  BCol,
  BFormInput,
  BButton,
  BTable,
  BLink,
  BBadge,
  BPagination,
  BSpinner,
  BTooltip,
} from 'bootstrap-vue'

import vSelect from 'vue-select'
import { onUnmounted, ref } from '@vue/composition-api'

import store from '@/store'
import useDemandList from './useDemandList'
import demandStoreModule from './demandStoreModule'
import AlertDialog from '@/components/AlertDialog.vue'
import { formatDate, formatCurrency } from '../../@core/utils/filter'

import usePropertyTypesList from '../propertyTypes/usePropertyTypesList'
import usePropertyActionsList from '../propertyActions/usePropertyActionsList'
import propertyTypesStoreModule from '../propertyTypes/propertyTypesStoreModule'
import propertyActionsStoreModule from '../propertyActions/propertyActionsStoreModule'

import PropertyList from '../property/PropertyList.vue'

export default {
  name: 'DemandList',
  components: {
    BCard,
    BRow,
    BCol,
    BFormInput,
    BButton,
    BTable,
    BLink,
    BBadge,
    BPagination,
    BSpinner,
    BTooltip,

    vSelect,
    AlertDialog,

    PropertyList,
  },
  data() {
    return {
      formatDate,
      formatCurrency,
      deleteModal: false,
      matchesModal: false,
      selectedItem: {},
    }
  },
  methods: {
    localeTitle(value) {
      const locale = this.$i18n.locale

      switch (locale) {
        case 'es':
          return value.titleEs
        case 'sv':
          return value.titleSw
        default:
          return value.titleEn
      }
    },
  },
  setup() {
    const DEMAND_APP_STORE_MODULE_NAME = 'app-demand'
    const PROPERTY_TYPES_APP_STORE_MODULE_NAME = 'app-property-types'
    const PROPERTY_ACTIONS_APP_STORE_MODULE_NAME = 'app-property-actions'

    // Register modules
    if (!store.hasModule(DEMAND_APP_STORE_MODULE_NAME)) {
      store.registerModule(DEMAND_APP_STORE_MODULE_NAME, demandStoreModule)
    }

    if (!store.hasModule(PROPERTY_TYPES_APP_STORE_MODULE_NAME)) {
      store.registerModule(
        PROPERTY_TYPES_APP_STORE_MODULE_NAME,
        propertyTypesStoreModule,
      )
    }

    if (!store.hasModule(PROPERTY_ACTIONS_APP_STORE_MODULE_NAME)) {
      store.registerModule(
        PROPERTY_ACTIONS_APP_STORE_MODULE_NAME,
        propertyActionsStoreModule,
      )
    }

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(DEMAND_APP_STORE_MODULE_NAME))
        store.unregisterModule(DEMAND_APP_STORE_MODULE_NAME)
      if (store.hasModule(PROPERTY_TYPES_APP_STORE_MODULE_NAME))
        store.unregisterModule(PROPERTY_TYPES_APP_STORE_MODULE_NAME)
      if (store.hasModule(PROPERTY_ACTIONS_APP_STORE_MODULE_NAME))
        store.unregisterModule(PROPERTY_ACTIONS_APP_STORE_MODULE_NAME)
    })

    const propertyTypes = ref(null)
    const propertyActions = ref(null)

    const { listPropertyTypes } = usePropertyTypesList()

    listPropertyTypes().then((response) => {
      propertyTypes.value = response
    })

    const { listPropertyActions } = usePropertyActionsList()

    listPropertyActions().then((response) => {
      propertyActions.value = response
    })

    const {
      fetchDemand,
      tableColumns,
      perPage,
      currentPage,
      totalDemand,
      dataMeta,
      perPageOptions,
      sortBy,
      isSortDirDesc,
      refDemandListTable,
      refetchData,
      deleteDemand,
      resolveStatusText,
      resolveStatusVariant,
      refQuery,
      selectedPropertyTypes,
      selectedPropertyActions,
    } = useDemandList()

    return {
      fetchDemand,
      tableColumns,
      perPage,
      currentPage,
      totalDemand,
      dataMeta,
      perPageOptions,
      sortBy,
      isSortDirDesc,
      refDemandListTable,
      refetchData,
      deleteDemand,
      resolveStatusText,
      resolveStatusVariant,

      refQuery,
      selectedPropertyTypes,
      selectedPropertyActions,

      propertyTypes,
      propertyActions,
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
</style>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

.vs__deselect svg {
  fill: #fff;
}

.dark-layout .vs__deselect svg {
  fill: $primary;
}
</style>
