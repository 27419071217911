import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'
import router from '@/router'
import i18n from '@/libs/i18n/index'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function () {
  const toast = useToast()

  const refDemandListTable = ref(null)

  // Table Handlers
  const tableColumns = computed(() => {
    return [
      { key: 'id', label: i18n.t('demandsTable.ref'), sortable: true },
      {
        key: 'applicant.name',
        label: i18n.t('demandsTable.name'),
        sortable: true,
      },
      {
        key: 'applicant.lastName',
        label: i18n.t('demandsTable.last'),
        sortable: true,
      },
      {
        key: 'propertyTypes',
        label: i18n.t('demandsTable.propertyType'),
        sortable: true,
      },
      // { key: 'propertyAction', label: 'Property action', sortable: true },
      {
        key: 'priceRange',
        label: i18n.t('demandsTable.priceRange'),
        sortable: true,
      },
      {
        key: 'createdAt',
        label: i18n.t('demandsTable.creationDate'),
        sortable: true,
      },
      { key: 'matches', label: i18n.t('demandsTable.matches'), sortable: true },
      { key: 'isActive', label: i18n.t('demandsTable.active'), sortable: true },
      { key: 'actions', label: '' },
    ]
  })

  const perPage = ref(10)
  const totalDemand = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [10, 25, 50, 100]
  const sortBy = ref('id')
  const isSortDirDesc = ref(true)

  const refQuery = ref('')
  const selectedPropertyTypes = ref([])
  const selectedPropertyActions = ref([])

  const dataMeta = computed(() => {
    const localItemsCount = refDemandListTable.value
      ? refDemandListTable.value.localItems.length
      : 0

    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalDemand.value,
    }
  })

  /**
   * Refreshes the demands table.
   */
  const refetchData = () => {
    refDemandListTable.value.refresh()
  }

  watch(
    [
      currentPage,
      perPage,
      refQuery,
      selectedPropertyTypes,
      selectedPropertyActions,
    ],
    () => {
      refetchData()
    },
  )

  const onSubmit = (data) => {
    store
      .dispatch('app-demand/setDemand', data)
      .then(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: i18n.t('toasts.save.success'),
            icon: 'CheckIcon',
            variant: 'success',
          },
        })

        router.push({ name: 'demands-list' })
      })
      .catch((e) => {
        toast({
          component: ToastificationContent,
          props: {
            title: i18n.t('toasts.save.error'),
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  const fetchDemand = (ctx, callback) => {
    store
      .dispatch('app-demand/fetchDemands', {
        headers: {
          'X-Sort': `demands.${sortBy.value}`,
          'X-Sort-Order': isSortDirDesc.value ? 'desc' : 'asc',
        },
        query: {
          id: refQuery.value,
          propertyTypes: selectedPropertyTypes.value
            .map((t) => t.key)
            .join('|'),
          propertyActions: selectedPropertyActions.value
            .map((a) => a.label)
            .join('|'),
          page: currentPage.value,
          perPage: perPage.value,
        },
      })
      .then((res) => {
        const { total } = res
        totalDemand.value = total
        callback(res.data)
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: i18n.t('toasts.fetch.error'),
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  const deleteDemand = (id) => {
    store
      .dispatch('app-demand/deleteDemand', id)
      .then(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: i18n.t('toasts.delete.success'),
            icon: 'CheckIcon',
            variant: 'success',
          },
        })

        refetchData()
      })
      .catch((e) => {
        toast({
          component: ToastificationContent,
          props: {
            title: i18n.t('toasts.delete.error'),
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  const resolveStatusVariant = (status) => {
    return status ? 'success' : 'warning'
  }

  const resolveStatusText = (status) => {
    return status ? 'active' : 'inactive'
  }

  return {
    fetchDemand,
    tableColumns,
    perPage,
    currentPage,
    totalDemand,
    dataMeta,
    perPageOptions,
    sortBy,
    isSortDirDesc,
    refDemandListTable,

    refQuery,
    selectedPropertyTypes,
    selectedPropertyActions,

    refetchData,
    onSubmit,
    deleteDemand,

    resolveStatusText,
    resolveStatusVariant,
  }
}
